import { css } from 'astroturf';
import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import Feature from '../Feature';

const styles = css`
  .featureList {
    position: relative;
    padding: 0rem 0 5rem;
    text-align: center;
    scroll-margin-top: 100px;
    color: #0a1530;

    & > h2 {
      font-weight: 800;
      font-size: calc(18px + 0.6vw);
      line-height: 1.25;
      margin: auto;
      margin-bottom: 0.5rem;
      margin-top: 0;
      letter-spacing: -0.015em;
      max-width: 60rem;

      padding-left: calc(1rem + env(safe-area-inset-left, 0));
      padding-right: calc(1rem + env(safe-area-inset-right, 0));
    }
    & > p {
      font-size: calc(16px + 0.25vw);
      font-weight: 400;
      position: relative;
      max-width: 52rem;
      margin: 0 auto 1.5rem;
      line-height: 1.6;

      padding-left: calc(1rem + env(safe-area-inset-left, 0));
      padding-right: calc(1rem + env(safe-area-inset-right, 0));
      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }
  }
`;

function FeatureList() {
  return (
    <StaticQuery
      query={awardsquery}
      render={(data) => (
        <section className={styles.featureList}>
          <Feature gatsbyImg={data.capterrabestvalue2024} full noshadow type="light" brow="Capterra" smalltitle>
            <h3>Best Value 2024</h3>
            <p>Reviewers rated Polypane in the top 25% for value in the browser category on Capterra.</p>
          </Feature>
          <Feature gatsbyImg={data.getappbestfunctionality2024} full noshadow type="light" brow="GetApp" smalltitle>
            <h3>Best Functionality and Features 2024</h3>
            <p>
              Reviewers on GetApp ranked Polypane high for best functionality and featured in two separate categories.
            </p>
          </Feature>
          <Feature
            gatsbyImg={data.euenterprise}
            full
            noshadow
            type="light"
            brow="European Enterprise Awards 2023"
            smalltitle
          >
            <h3>Best Digital Developer Tools Company</h3>
            <p>
              In the European Enterprise Awards organised by EU Business News Polypane was awarded "Best Digital
              Developer Tools Company" for West Europe.
            </p>
          </Feature>
          <Feature gatsbyImg={data.techinnovator} full noshadow type="light" brow="Innovation in business" smalltitle>
            <h3>Most Innovative Web Development Tool 2023</h3>
            <p>
              Polypane was recognised as the Most Innovative Web Development Tool 2023 by Innovation in Business in
              their Technology Innovator Awards.
            </p>
          </Feature>
          <Feature gatsbyImg={data.g2momentum} full noshadow type="light" brow="G2.com" smalltitle>
            <h3>Momentum Leader Fall 2023</h3>
            <p>
              Polypane is a Momentum leader for Fall 2023 on G2.com. Momentum leaders are the top rated products in a
              category.
            </p>
          </Feature>
          <Feature
            gatsbyImg={data.softwareadvicefrontrunner}
            full
            noshadow
            type="light"
            brow="Software Advice"
            smalltitle
          >
            <h3>2023 Frontrunner</h3>
            <p>
              Polypane is a Frontrunner on Software Advice for both the Website optimization tools and app development
              categories. Frontrunners list has the top rated tools in a category.
            </p>
          </Feature>
          <Feature gatsbyImg={data.capterrashortlist} full noshadow type="light" brow="Capterra" smalltitle>
            <h3>Shortlist 2023</h3>
            <p>
              Polypane is listed on the 2023 Capterra Shortlist in two categories: Website optimization tools and
              Application Development Software. The shortlist contains the highest rated and most popular tools in a
              category.
            </p>
          </Feature>
          <Feature gatsbyImg={data.worldfestival2} full noshadow type="light" brow="Worldfestival" smalltitle>
            <h3>Top 50 Startup 2023</h3>
            <p>
              At Worldfestival 2023 Polypane was once again voted one of the top fifty startups worldwide. We previously
              competed in the Worldfestival Innovation awards in 2021, also winning a top 50 award.
            </p>
          </Feature>
          <Feature gatsbyImg={data.gac} full noshadow type="light" brow="GetApp" smalltitle>
            <h3>Category leader 2022, 2023</h3>
            <p>
              Polypane was featured as Category Leader in the Website Optimization Tools category at GetApp two years in
              a row.
            </p>
          </Feature>
          <Feature gatsbyImg={data.benelux2} full noshadow type="light" brow="EU Business News" smalltitle>
            <h3>Best Web Developing Tool Company</h3>
            <p>
              In the Benelux Enterprise Awards 2022, organised by EU Business News, Polypane was awarded "Best Web
              Developing Tool Company".
            </p>
          </Feature>
          <Feature
            gatsbyImg={data.softwareadvicebestcustomersupport}
            full
            noshadow
            type="light"
            brow="Software Advice"
            smalltitle
          >
            <h3>Best customer support 2022</h3>
            <p>Polypane was awarded Best Customer Support 2022 by Software Advice.</p>
          </Feature>
          <Feature gatsbyImg={data.hackernoon} full noshadow type="light" brow="Hackernoon" smalltitle>
            <h3>#1 startup in our hometown!</h3>
            <p>
              In the 2021 startup of the year awards by Hackernoon, Polypane won the #1 spot in our hometown of
              Zoetermeer, The Netherlands.
            </p>
          </Feature>
          <Feature gatsbyImg={data.kvk} full noshadow type="light" brow="Chamber of Commerce" smalltitle>
            <h3>KVK Innovatie Top 100</h3>
            <p>
              Every year the Dutch Chamber of Commerce releases their Innovation Top 100. For 2021 Polypane made the
              list as <strong>one of the most innovative companies in The Netherlands</strong>.
            </p>
          </Feature>
          <Feature gatsbyImg={data.console} full noshadow type="light" brow="Console.dev" smalltitle>
            <h3>Developer Tool of the Week</h3>
            <p>
              Console.dev reviews and lists the best Developer tools. Polypane was chosen{' '}
              <strong>developer tool of the week</strong> on the 22nd of July 2021.
            </p>
          </Feature>
          <Feature gatsbyImg={data.goglobal} full noshadow type="light" brow="International Trade Council" smalltitle>
            <h3>Go Global awards 2021 Information Technology Frontrunner</h3>
            <p>
              Competing with over 4000 other organisations in the Go Global awards, Polypane was highlighted as an{' '}
              <b>Information Technology Frontrunner</b>.
            </p>
          </Feature>
          <Feature gatsbyImg={data.benelux} full noshadow type="light" brow="EU Business News" smalltitle>
            <h3>Best Website/Web App Test & Development Browser 2021</h3>
            <p>
              In the Benelux Enterprise Awards 2021, organised by EU Business News, Polypane was awarded "Best
              Website/Web App Test & Development Browser".
            </p>
          </Feature>
          <Feature gatsbyImg={data.worldfestival} full noshadow type="light" brow="Worldfestival" smalltitle>
            <h3>Top 50 startup 2021</h3>
            <p>
              The WorldFestival 2021 Innovation Awards program is the largest worldwide awards competition identifying
              and recognizing global innovation. Out of 1000+ startups, Polypane was chosen as on of the fifty best
              startups.
            </p>
          </Feature>
          <Feature gatsbyImg={data.corporatevision} full noshadow type="light" brow="Corporate Vision" smalltitle>
            <h3>Best Full-Service Web Development Solutions Provider 2021</h3>
            <p>
              In the Corporate Vision Technology Innovator Awards 2021, Polypane was awarded the "best full-service web
              development solutions provider".
            </p>
          </Feature>

          <Feature gatsbyImg={data.csstricks} full noshadow type="light" brow="CSS Tricks" smalltitle>
            <h3>Best browser for responsive design</h3>
            <p>
              CSS Tricks compared six different browsers for their responsive design tools and concludes that Polypane
              takes the crown, saying it has <q>it has the most well-considered feature set</q> and lauding our unified
              element inspector that lets you inspect and edit across all panes simultaneously.
            </p>
          </Feature>
        </section>
      )}
    />
  );
}

export default FeatureList;

const awardsquery = graphql`
  query awardsQuery {
    csstricks: file(relativePath: { eq: "images/awards/csstricks.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    corporatevision: file(relativePath: { eq: "images/awards/corporatevision.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    worldfestival: file(relativePath: { eq: "images/awards/worldfestival.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    benelux: file(relativePath: { eq: "images/awards/benelux.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    benelux2: file(relativePath: { eq: "images/awards/benelux2.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    goglobal: file(relativePath: { eq: "images/awards/goglobal.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    kvk: file(relativePath: { eq: "images/awards/kvk.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    console: file(relativePath: { eq: "images/awards/console.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    hackernoon: file(relativePath: { eq: "images/awards/hackernoon.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    gac: file(relativePath: { eq: "images/awards/gacategoryleader.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    worldfestival2: file(relativePath: { eq: "images/awards/worldfestival2.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    capterrashortlist: file(relativePath: { eq: "images/awards/capterra-shortlist.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    softwareadvicefrontrunner: file(relativePath: { eq: "images/awards/softwareadvice-frontrunner.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    softwareadvicebestcustomersupport: file(relativePath: { eq: "images/awards/softwareadvice-customersupport.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    g2momentum: file(relativePath: { eq: "images/awards/g2-momentum.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    techinnovator: file(relativePath: { eq: "images/awards/technology-innovator-awards.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    euenterprise: file(relativePath: { eq: "images/awards/euenterprise2023.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    capterrabestvalue2024: file(relativePath: { eq: "images/awards/capterrabestvalue2024.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    getappbestfunctionality2024: file(relativePath: { eq: "images/awards/getappbestfunctionality2024.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;
